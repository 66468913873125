import { render, staticRenderFns } from "./transport-page.vue?vue&type=template&id=2fbbfc0b&scoped=true&"
import script from "./transport-page.vue?vue&type=script&lang=js&"
export * from "./transport-page.vue?vue&type=script&lang=js&"
import style0 from "./transport-page.vue?vue&type=style&index=0&id=2fbbfc0b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fbbfc0b",
  null
  
)

export default component.exports