var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('input-list-item',{ref:"inputListItem",attrs:{"form":_vm.form,"model":_vm.model,"label":_vm.label,"accordion":"tugs","open":""},on:{"remove":_vm.remove}},[_c('input-select-or-custom',{attrs:{"form":_vm.form,"model":_vm.model,"model-key":"companyName","validators":{ required: true },"label":_vm.$t('transport.tugs.companyName.label'),"placeholder":_vm.$t('transport.tugs.companyName.placeholder'),"options":[
      { value: 'Boluda' },
      { value: 'BTS' },
      { value: 'Fairplay' },
      { value: 'Gepke' },
      { value: 'Hebo' },
      { value: 'Lekstroom' },
      { value: 'Limburgia' },
      { value: 'Muller Dordrecht' },
      { value: 'Stemat' },
      { value: 'SVW' },
      { value: 'VKV' },
      { value: 'van der Wees' },
      { value: 'van Wijngaarden' } ]}}),_c('input-text',{attrs:{"form":_vm.form,"model":_vm.model,"model-key":"name","label":_vm.$t('transport.tugs.name.label'),"placeholder":_vm.$t('transport.tugs.name.placeholder')}}),_c('input-select',{attrs:{"form":_vm.form,"model":_vm.model,"model-key":"type","validators":{ required: true },"label":_vm.$t('transport.tugs.type.label'),"placeholder":_vm.$t('transport.tugs.type.placeholder'),"options":[
      {
        value: 'TUG',
        text: _vm.$t('transport.tugs.type.tug'),
      },
      {
        value: 'PUSHER',
        text: _vm.$t('transport.tugs.type.pusher'),
      },
      {
        value: 'COMBI',
        text: _vm.$t('transport.tugs.type.combi'),
      } ]}}),(_vm.model.type === 'COMBI' || _vm.model.type === 'TUG')?_c('input-number',{attrs:{"form":_vm.form,"model":_vm.model,"model-key":"bollardPull","validators":{ required: true },"label":_vm.$t('transport.tugs.bollardPull.label'),"placeholder":_vm.$t('transport.tugs.bollardPull.placeholder'),"max-decimals":0,"append":"t"}}):_vm._e(),(_vm.model.type === 'COMBI' || _vm.model.type === 'PUSHER')?_c('input-number',{attrs:{"form":_vm.form,"model":_vm.model,"model-key":"power","validators":{ required: true },"label":_vm.$t('transport.tugs.power.label'),"append":_vm.$t('generic.abbr.kw'),"placeholder":_vm.$t('transport.tugs.power.placeholder'),"max-decimals":0}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }