<template>
  <form-item
    label-tag="h4"
    :explanation="explanation"
    :error-message="errorMessage"
    :model-key="modelKey"
    :blocked="blocked"
  >
    <input-list id="tugs-input-list" :disable-btn="disableNew" :label="addBtnLabel" @add-item="addItem()">
      <div v-if="form[modelKey].subForms.length === 0 && emptyMessage" class="empty-msg">
        {{ emptyMessage }}
      </div>

      <tug
        v-for="(tugForm, index) in form[modelKey].subForms"
        :key="tugForm.id"
        :label="$t('transport.tugs.label')"
        ref="items"
        :form="tugForm"
        :model="value[index]"
        @remove="removeItem(tugForm.id)"
      />
    </input-list>
  </form-item>
</template>

<script>
import withModel from 'poronline-shared-vue-components/components/form/with-model';
import InputList from 'poronline-shared-vue-components/components/form/input-list';
import withListOfValues from '../../components/form/with-list-of-values';
import Tug from './tug';

export default {
  name: 'Tugs',
  props: { addBtnLabel: String, explanation: String, emptyMessage: String },
  mixins: [withModel, withListOfValues],
  components: { InputList, Tug },

  computed: {
    disableNew() {
      const subForms = this.formField?.subForms ?? [];

      return subForms.length > 0 && subForms.some((subForm) => false === subForm.$valid);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~poronline-shared-vue-components/styles/variables';

.list-group {
  .list-group-item:not(:last-child) {
    border-top-width: 1px;
    margin-bottom: 1em;
  }
}

.empty-msg {
  background: $gray-light-2;
  border-radius: 1px;
  margin-bottom: 1em;
  text-align: center;
  line-height: 3em;
}
</style>
