import maxBy from 'lodash/maxBy';
import findIndex from 'lodash/findIndex';
import clone from 'lodash/clone';

export default {
  name: 'withListOfValues',
  created() {
    const value = this.getValue();
    this.$set(
      this.formField,
      'subForms',
      (value ?? []).map((form, index) => ({
        id: index,
        $valid: null,
        $dirty: this.form.$dirty,
      }))
    );

    // watch parent form to copy $dirty to all sub forms
    this.$watch(
      () => this.form?.$dirty,
      (dirty) => {
        this.formField.subForms.forEach((form, index) => {
          form.$dirty = dirty;

          if (!form.$valid && this.$refs.items?.[index]) {
            // open collapse when submitting and sub form is invalid
            this.$refs.items[index].openCollapse();
          }
        });
      },
      { immediate: true }
    );

    // watch
    this.$watch(
      'subFormsAreValid',
      (valid) => {
        this.$set(this.formField, '$childrenValid', valid);
      },
      { immediate: true }
    );

    // watch
    this.$watch(
      'subFormsAreValidating',
      (validating) => {
        this.$set(this.formField, '$childrenValidating', validating);
      },
      { immediate: true }
    );
  },
  computed: {
    subFormsAreValid() {
      return (
        0 === this.formField.subForms.filter((form) => false === form.$valid || false === form.$childrenValid).length
      );
    },
    subFormsAreValidating() {
      return (
        this.formField.subForms.filter((form) => true === form.$validating || true === form.$childrenValidating)
          .length > 0
      );
    },
  },
  methods: {
    addItem(defaultModel = {}) {
      if (!this.formField.$childrenValid) {
        this.formField.subForms.forEach((form, index) => {
          if (!form.$valid) {
            form.$dirty = true;
            this.$refs.items[index].openCollapse();
          }
        });

        this.$nextTick(() => {
          this.$scrollTo('.form-group.is-invalid');
        });
        return;
      }

      const model = clone(defaultModel);
      if (!this.value) {
        this.value = [model];
      } else {
        this.value.push(model);
      }

      const id = this.formField.subForms.length ? maxBy(this.formField.subForms, 'id').id + 1 : 1;
      this.formField.subForms.push({ id, $dirty: this.form.$dirty });
    },
    removeItem(id) {
      const index = findIndex(this.formField.subForms, (form) => form.id === id);
      this.$delete(this.formField.subForms, index);

      if (1 === this.value.length) {
        this.value = undefined;
      } else {
        this.$delete(this.value, index);
      }
    },
  },
};
