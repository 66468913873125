<template>
  <page container-type="centered">
    <page-row>
      <page-column>
        <echo-form
          :title="$t('transport.title')"
          :explanation="$t('transport.explanation')"
          :model="model"
          :form="form"
          submit-action="REQUEST_TRANSPORT"
          data-test="transport"
        >
          <field-set :legend="$t('transport.legend.visit')" data-test="visit">
            <input-ucrn
              :form="form"
              :model="model"
              model-key="ucrn"
              :validators="{
                required: { messageTranslationKey: 'form.ucrn.required' },
                myUcrn: true,
              }"
              :label="$t('transport.ucrn')"
              data-test="ucrn"
            />

            <input-select
              :form="form"
              :model="model"
              model-key="movementCommId"
              :validators="{ required: true }"
              :label="$t('transport.movement.label')"
              :placeholder="$t('transport.movement.placeholder')"
              :options="movementOptions"
              data-test="movement"
            />

            <ship-summary v-if="vesselForSummary" :vessel="vesselForSummary" :movement="movementForSummary" />

            <input-ucrn
              :form="form"
              :model="model"
              model-key="leadTugUcrn"
              :label="$t('transport.leadTug.ucrn')"
              :validators="{
                myUcrn: true,
              }"
              data-test="lead-tug-ucrn"
            />

            <template v-if="selectedLeadTug">
              <div class="form-group" data-test="lead-tug-summary">
                <h6>{{ $t('transport.leadTug.summary.title') }}</h6>
                <dl>
                  <dt>{{ $t('transport.leadTug.summary.shipName') }}</dt>
                  <dd data-test="tug-name">{{ selectedLeadTug.shipName }}</dd>
                </dl>
              </div>
            </template>

            <input-number
              v-if="selectedLeadTug"
              :form="form"
              :model="model"
              model-key="leadTugBollardPull"
              :validators="{ required: true }"
              :label="$t('transport.leadTug.bollardPull.label')"
              :placeholder="$t('transport.leadTug.bollardPull.placeholder')"
              :max-decimals="0"
              append="t"
            />

            <input-yes-no
              v-if="selectedLeadTug"
              :form="form"
              :model="model"
              model-key="leadTugToBerth"
              :question="$t('transport.leadTug.toBerth.label')"
              :validators="{ required: true }"
              :options="[
                { value: true, text: $t('generic.options.yes') },
                { value: false, text: $t('generic.options.no') },
              ]"
              data-test="lead-tug-to-berth"
            />

            <b-alert
              class="info-message"
              :show="selectedLeadTug && typeof model.leadTugToBerth === 'boolean'"
              variant="info"
            >
              <strong>{{ $t('generic.message.caution') }}</strong>
              {{
                $t(
                  model.leadTugToBerth
                    ? 'transport.leadTug.toBerth.message.yes'
                    : 'transport.leadTug.toBerth.message.no'
                )
              }}
            </b-alert>
          </field-set>

          <field-separator />

          <field-set :legend="$t('transport.legend.cargo')" data-test="cargo">
            <input-yes-no
              :form="form"
              :model="model"
              model-key="hasCargo"
              :question="$t('transport.hasCargo.label')"
              :validators="{ required: true }"
              :options="[
                { value: false, text: $t('transport.hasCargo.options.false') },
                { value: true, text: $t('transport.hasCargo.options.true') },
              ]"
              data-test="has-cargo"
            />

            <b-alert class="info-message" :show="model.hasCargo" variant="info">
              {{ $t('transport.hasCargo.message') }}
            </b-alert>

            <input-text
              v-if="model.hasCargo"
              :form="form"
              :model="model"
              model-key="cargoDescription"
              :placeholder="$t('transport.cargoDescription.placeholder')"
              :validators="{ required: true }"
              :label="$t('transport.cargoDescription.label')"
              data-test="cargo-description"
            />

            <input-number
              v-if="model.hasCargo"
              :form="form"
              :model="model"
              model-key="overhangPortSide"
              :label="$t('transport.overhangPort.label')"
              :placeholder="$t('transport.overhangPort.placeholder')"
              append="m"
              data-test="overhang-port-side"
            />

            <input-number
              v-if="model.hasCargo"
              :form="form"
              :model="model"
              model-key="overhangStarboardSide"
              :label="$t('transport.overhangStarboard.label')"
              :placeholder="$t('transport.overhangStarboard.placeholder')"
              append="m"
              data-test="overhang-starboard-side"
            />

            <input-number
              key="airdraft"
              :form="form"
              :model="model"
              model-key="airdraft"
              :label="$t('transport.airdraft.label')"
              :placeholder="$t('transport.airdraft.placeholder')"
              :validators="{ required: true }"
              append="m"
              data-test="airdraft"
            />

            <input-number
              v-if="model.hasCargo"
              :form="form"
              :model="model"
              model-key="windSurface"
              :label="$t('transport.windSurface.label')"
              :placeholder="$t('transport.windSurface.placeholder')"
              :validators="{ required: true }"
              append="m²"
              data-test="wind-surface"
            />
          </field-set>

          <field-separator />

          <field-set :legend="$t('transport.legend.assistance')" data-test="tugs">
            <tugs
              :form="form"
              :model="model"
              model-key="tugs"
              :empty-message="$t('transport.tugs.emptyMessage')"
              :add-btn-label="$t('transport.tugs.addBtnLabel')"
              :explanation="$t('transport.tugs.explanation')"
            />

            <template v-if="showConfiguraton">
              <div class="form-group" data-test="tug-configuration">
                <h6>{{ $t('transport.tugs.config.label') }}</h6>
                <dl>
                  <dt>{{ $t('transport.tugs.config.leadTugToBerth') }}</dt>
                  <dd>{{ showLeadTugToBerthInConfiguration }}</dd>
                  <dt>{{ $t('transport.tugs.config.amountTug') }}</dt>
                  <dd>{{ (model.tugs || []).filter((tug) => tug.type === 'TUG').length }}</dd>
                  <dt>{{ $t('transport.tugs.config.amountPusher') }}</dt>
                  <dd>{{ (model.tugs || []).filter((tug) => tug.type === 'PUSHER').length }}</dd>
                  <dt>{{ $t('transport.tugs.config.amountCombi') }}</dt>
                  <dd>{{ (model.tugs || []).filter((tug) => tug.type === 'COMBI').length }}</dd>
                  <dt>{{ $t('transport.tugs.config.amountBollardPull') }}</dt>
                  <dd>
                    {{
                      (model.tugs || [])
                        .map((tug) => tug.bollardPull || 0)
                        .reduce(
                          (sum, pull) => sum + pull,
                          selectedLeadTug && model.leadTugToBerth ? model.leadTugBollardPull || 0 : 0
                        )
                    }}
                    t
                  </dd>
                </dl>
              </div>
            </template>
          </field-set>

          <field-separator />

          <field-set :legend="$t('transport.legend.other')" data-test="other-data">
            <input-number
              :form="form"
              :model="model"
              model-key="runners"
              :label="$t('transport.runners.label')"
              :placeholder="$t('transport.runners.placeholder')"
              :validators="{ required: true }"
              :max-decimals="0"
              data-test="runners"
            />

            <input-yes-no
              :form="form"
              :model="model"
              model-key="hasRwsLicense"
              :question="$t('transport.rwsLicense.label')"
              :explanation="$t('transport.rwsLicense.explanation')"
              :validators="{ required: true }"
              :options="[
                { value: true, text: $t('generic.options.yes') },
                { value: false, text: $t('generic.options.no') },
              ]"
              data-test="has-rws-license"
            />

            <b-alert class="info-message" :show="model.hasRwsLicense" variant="info">
              {{ $t('transport.rwsLicense.message') }}
            </b-alert>

            <input-textarea
              :form="form"
              :model="model"
              model-key="remarks"
              :label="$t('transport.remarks.label')"
              :placeholder="$t('transport.remarks.placeholder')"
              :validators="{ maxLength: { length: 2000 } }"
              data-test="remarks"
            />

            <input-file
              :form="form"
              :label="$t('form.input.attachments')"
              :model="model"
              :validators="{ files: { maxFiles: 10 } }"
              model-key="attachments"
              data-test="attachments-transport"
            />
          </field-set>
        </echo-form>
      </page-column>
    </page-row>
  </page>
</template>

<script>
import { BAlert } from 'bootstrap-vue';
import PageRow from 'poronline-shared-vue-components/components/page/row';
import PageColumn from 'poronline-shared-vue-components/components/page/column';
import FieldSet from 'poronline-shared-vue-components/components/form/field-set';
import FieldSeparator from 'poronline-shared-vue-components/components/form/field-separator';
import InputUcrn from 'poronline-shared-vue-components/components/form/input-ucrn';
import InputNumber from 'poronline-shared-vue-components/components/form/input-number';
import InputText from 'poronline-shared-vue-components/components/form/input-text';
import InputTextarea from 'poronline-shared-vue-components/components/form/input-textarea';
import InputSelect from 'poronline-shared-vue-components/components/form/input-select';
import InputYesNo from 'poronline-shared-vue-components/components/form/input-yes-no';
import Page from '../../components/page/page';
import EchoForm from '../../components/form/echo-form';
import InputFile from '../../components/form/input-file';
import ShipSummary from '../../components/form/ship-summary';
import withVisit from '../../components/form/with-visit';
import Tugs from './tugs';

export default {
  components: {
    BAlert,
    Page,
    PageRow,
    PageColumn,
    EchoForm,
    FieldSet,
    FieldSeparator,
    InputFile,
    InputUcrn,
    InputNumber,
    InputText,
    InputTextarea,
    InputSelect,
    InputYesNo,
    ShipSummary,
    Tugs,
  },
  mixins: [withVisit],
  props: {
    copy: Object,
  },
  data() {
    const model = {
      tugs: [],
    };

    if (this.copy) {
      Object.assign(model, this.copy);
    }

    if (this.$route.query.ucrn) {
      model.ucrn = this.$route.query.ucrn;
    }

    return { model, form: {}, leadTugVisit: null };
  },
  computed: {
    vesselForSummary() {
      return this.model.ucrn && this.form?.ucrn?.$valid
        ? {
            shipName: this.selectedVessel?.shipName,
            shipNumber: this.selectedVessel?.shipNumber,
            category: this.selectedVessel?.category,
            length: this.selectedVessel?.length,
            beam: this.selectedVessel?.beam,
          }
        : undefined;
    },
    selectedLeadTug() {
      return this.vesselsByImo[this.leadTugVisit?.vessel?.imo];
    },
    movementForSummary() {
      const movement =
        this.visit && this.model.movementCommId
          ? this.visit.movements.find((movement) => movement.commId === this.model.movementCommId)
          : undefined;

      return {
        draught: movement?.draught,
      };
    },
    showConfiguraton() {
      const hasLeadTug = this.model.leadTugToBerth && 'number' === typeof this.model.leadTugBollardPull;
      const hasAssistingTugs = this.form.tugs?.$valid && this.model.tugs?.length > 0;

      return hasLeadTug || hasAssistingTugs;
    },
    showLeadTugToBerthInConfiguration() {
      if (this.model.leadTugUcrn && 'boolean' === typeof this.model.leadTugToBerth) {
        return this.$t(this.model.leadTugToBerth ? 'generic.options.yes' : 'generic.options.no');
      }
      return this.$t('generic.options.na');
    },
  },
  watch: {
    'form.leadTugUcrn.$validValue': {
      handler: async function (ucrn) {
        if (!ucrn) {
          this.leadTugVisit = null;

          if ('' === ucrn) {
            this.model.leadTugUcrn = undefined;
          }

          return;
        }

        try {
          const visit = await this.$store.dispatch('LOAD_VISIT', ucrn);
          if (visit.failure) {
            this.leadTugVisit = null;
            return;
          }

          if (visit?.vessel?.imo) {
            this.$store.dispatch('LOAD_SHIP', { ucrn, imo: visit.vessel.imo, silent: true });
          }

          this.leadTugVisit = visit ?? null;
        } catch {
          this.leadTugVisit = null;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.info-message {
  border-width: 1px;
  border-radius: 2px;
}

dl {
  display: grid;
  grid-template-columns: 1fr 2fr;

  dt {
    font-weight: normal;
  }
  dd {
    margin: 0;
  }
}
</style>
