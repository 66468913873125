<template>
  <input-list-item
    :form="form"
    :model="model"
    :label="label"
    accordion="tugs"
    @remove="remove"
    ref="inputListItem"
    open
  >
    <input-select-or-custom
      :form="form"
      :model="model"
      model-key="companyName"
      :validators="{ required: true }"
      :label="$t('transport.tugs.companyName.label')"
      :placeholder="$t('transport.tugs.companyName.placeholder')"
      :options="[
        { value: 'Boluda' },
        { value: 'BTS' },
        { value: 'Fairplay' },
        { value: 'Gepke' },
        { value: 'Hebo' },
        { value: 'Lekstroom' },
        { value: 'Limburgia' },
        { value: 'Muller Dordrecht' },
        { value: 'Stemat' },
        { value: 'SVW' },
        { value: 'VKV' },
        { value: 'van der Wees' },
        { value: 'van Wijngaarden' },
      ]"
    />

    <input-text
      :form="form"
      :model="model"
      model-key="name"
      :label="$t('transport.tugs.name.label')"
      :placeholder="$t('transport.tugs.name.placeholder')"
    />

    <input-select
      :form="form"
      :model="model"
      model-key="type"
      :validators="{ required: true }"
      :label="$t('transport.tugs.type.label')"
      :placeholder="$t('transport.tugs.type.placeholder')"
      :options="[
        {
          value: 'TUG',
          text: $t('transport.tugs.type.tug'),
        },
        {
          value: 'PUSHER',
          text: $t('transport.tugs.type.pusher'),
        },
        {
          value: 'COMBI',
          text: $t('transport.tugs.type.combi'),
        },
      ]"
    />

    <input-number
      v-if="model.type === 'COMBI' || model.type === 'TUG'"
      :form="form"
      :model="model"
      model-key="bollardPull"
      :validators="{ required: true }"
      :label="$t('transport.tugs.bollardPull.label')"
      :placeholder="$t('transport.tugs.bollardPull.placeholder')"
      :max-decimals="0"
      append="t"
    />

    <input-number
      v-if="model.type === 'COMBI' || model.type === 'PUSHER'"
      :form="form"
      :model="model"
      model-key="power"
      :validators="{ required: true }"
      :label="$t('transport.tugs.power.label')"
      :append="$t('generic.abbr.kw')"
      :placeholder="$t('transport.tugs.power.placeholder')"
      :max-decimals="0"
    />
  </input-list-item>
</template>

<script>
import InputListItem from 'poronline-shared-vue-components/components/form/input-list-item';
import InputText from 'poronline-shared-vue-components/components/form/input-text';
import InputSelect from 'poronline-shared-vue-components/components/form/input-select';
import InputSelectOrCustom from 'poronline-shared-vue-components/components/form/input-select-or-custom';
import InputNumber from 'poronline-shared-vue-components/components/form/input-number';

export default {
  name: 'Tug',
  components: { InputListItem, InputText, InputSelect, InputSelectOrCustom, InputNumber },
  props: {
    model: {
      type: Object,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
    label: String,
  },
  methods: {
    remove(id) {
      this.$emit('remove', id);
    },
    openCollapse() {
      this.$refs.inputListItem.openCollapse();
    },
  },
};
</script>
